<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <v-row>
          <v-col cols="12" sm="6">
            <h1>{{ pet.name }}</h1>
          </v-col>
          <v-spacer />
          <v-col cols="12" sm="2">
            <v-btn block @click="$router.push({name: 'ViewPetVaccination'})">Upload vaccinations</v-btn>
            <v-btn block style="width: 212px;" class="mt-2" @click="$router.push({name: 'MedicationList'})">Add Medications</v-btn>
            <v-btn block style="width: 212px;" class="mt-2" @click="$router.push({name: 'OwnFoodList'})">Add Own Food</v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-0 mb-2" v-if="pet.avatar_location !== null">
          <v-col cols="12" sm="6" align="center">
            <v-avatar size="120"><v-img :src="pet.avatar_location">
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0 primary lighten--2"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img></v-avatar>
          </v-col>
        </v-row>
        <v-row class="mb-0">
            <v-col class="py-0" cols="12" sm="6">
              <text-input
                  v-model="pet.name"
                  rules="required"
                  label="Name*"
                  name="Name"
              ></text-input>
            </v-col>
          <v-col class="py-0" cols="12" sm="6">
            <google-places-autocomplete-input
                :id="`vet-name`"
                :default-text="pet.vet"
                v-model="pet.vet"
                v-on:placechanged="placeChanged"
                @inputChange="updateVetName"
                outlined
                :types="`veterinary_care`"
                :fields="['address_components', 'name', 'international_phone_number', 'formatted_address', 'geometry']"
                name="Vet Name"
                label="Vet Name*"
                country="au"
                required
                class="mb-0"
                placeholder="Vet Name*" />
          </v-col>
          <v-col class="py-0" cols="12" sm="6">
            <text-input
                v-model="pet.vet_phone"
                outlined
                label="Vet Phone"
                name="Vet Phone"
                vid="vet_phone"
            ></text-input>
          </v-col>
          <v-col class="py-0" cols="12" sm="6">
            <google-places-autocomplete-input
                :id="`vet-address`"
                :default-text="pet.vet_address"
                v-model="pet.vet_address"
                v-on:placechanged="placeChanged"
                @inputChange="updateVetAddress"
                outlined
                :types="`veterinary_care`"
                :fields="['address_components', 'name', 'international_phone_number', 'formatted_address', 'geometry']"
                name="Vet Address"
                label="Vet Address"
                placeholder="Vet Address"
                country="au"
                class="mb-0" />
          </v-col>
            <v-col class="py-0" cols="12" sm="6">
              <ValidationProvider tag="div" rules="required" name="Breed" vid="breed" v-slot="{ errors }">
                  <v-combobox
                      v-model="pet.breed"
                      :items="breeds"
                      label="Breed*"
                      outlined
                      :loading="loadingBreeds"
                      :error-messages="errors"
                      @change="validateBreedSelection"
                  />
              </ValidationProvider>
            </v-col>
            <v-col class="py-0" cols="12" sm="6">
              <select-input
                  v-model="pet.sex"
                  :items="sex"
                  rules="required"
                  label="Sex*"
                  outlined
              />
            </v-col>
          <v-col class="py-0" cols="12" sm="6">
            <select-input
                v-model="pet.desexed"
                :items="desexed"
                rules="required"
                label="Desexed*"
                outlined
            />
          </v-col>
          <v-col v-if="hasBirthday === true || pet.dob !== null"  class="py-0" cols="12" sm="6">
            <date-picker
                v-model="pet.dob"
                outlined
                label="Date of Birth*"
                name="Date of Birth"
                :max="today"
                hide-details
                rules="required"
            >
            </date-picker>
            <span v-if="pet.dob === null" @click="hasBirthday = false" class="caption text-decoration-underline">Don't know {{ pet.name }}'s date of birth. Click here to enter age ></span>
          </v-col>
          <v-col v-if="hasBirthday === false"  class="py-0" cols="6" sm="3">
            <text-input
                v-model="years"
                outlined
                label="Age (years)*"
                type="number"
                name="Age (years)"
                rules="required|min_value:0"
            >
            </text-input>
          </v-col>
          <v-col v-if="hasBirthday === false"  class="py-0" cols="6" sm="3">
            <text-input
                v-model="months"
                outlined
                label="Age (months)*"
                type="number"
                name="Age (months)"
                rules="required|max_value:11|min_value:0"
            >
            </text-input>
          </v-col>
          <v-col class="py-0" cols="12" sm="6">
            <text-input
                v-model="pet.instagram_handle"
                prefix="@"
                label="Instagram"
                name="Instagram"
            ></text-input>
          </v-col>
        </v-row>
        <v-row class="mb-0 mt-0">
          <v-col v-if="pet.avatar_location === null" class="py-0 pt-5 pt-sm-0" cols="12" sm="6">
            <dropzone-upload
                ref="dropzone"
                style="height: 204px"
                :useCustomSlot="true"
                :files.sync="files"
                :loading.sync="uploading"
                :options='{
                            maxFilesize: 10,
                            maxFiles: 1,
                            uploadMultiple: false,
                            acceptedFiles: "image/jpeg,image/png",
                        }'
            ><v-icon class="mt-2">mdi-upload</v-icon><p>Upload a profile picture (optional)</p><v-btn color="primary">Upload</v-btn>
            </dropzone-upload>
          </v-col>
          <v-col class="py-0 pt-5 pt-sm-0" cols="12" sm="6" v-if="pet.notes === ''">
            <ValidationProvider tag="div" rules="" name="Notes" v-slot="{ errors }">
              <v-textarea
                  :placeholder="`${pet.name} is sociable and loves to meet other dogs...`"
                  v-model="notes"
                  :error-messages="errors"
                  label="Tell us more about this pet"
                  auto-grow
                  outlined
                  rows="8"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="mb-0 mt-0">
          <v-col cols="12" sm="6">
            <v-btn color="success" block
                  type="submit"
                  :loading="loading"
                  :disabled="uploading"
            >
              Save
            </v-btn>
            <v-btn block class="mt-3" @click="$router.push({name: 'ViewPets'})">
              Back
            </v-btn>
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import SelectInput from "@/components/SelectInputComponent";
import TextInput from "@/components/TextInputComponent";
import DatePicker from "@/components/DatePickerComponent";
import PetsRepository from "@/api/PetsRepository";
import store from "@/store";
import DropzoneUpload from "@/components/DropzoneUpload";
import GooglePlacesAutocompleteInput from "@/components/GooglePlacesAutcompleteInput.vue";
var moment = require('moment');

export default {
  name: 'ManagePet',
  title: 'Manage pet',
  components: {
    GooglePlacesAutocompleteInput,
    DropzoneUpload,
    ValidationObserver,
    SelectInput,
    TextInput,
    DatePicker,
    ValidationProvider,
  },
  async beforeRouteEnter(to, from, next) {
      await store.dispatch("pet/get",to.params.id)
      next();
  },
  async beforeRouteUpdate(to, from, next) {
    await store.dispatch("pet/get",to.params.id)
    next();
  },
  async mounted() {
    this.loadingBreeds = true;
    this.breeds = await PetsRepository.getBreeds(this.pet.petType);
    this.loadingBreeds = false;
  },
  data: () => ({
    loading: false,
    loadingBreeds: false,
    breeds: [],
    hasBirthday: true,
    years: 0,
    months: 0,
    notes: '',
    files: [],
    uploading: false,
    sex: [
      {text: 'Male', value: 'M'},
      {text: 'Female', value: 'F'},
    ],
    desexed: [
      {text: 'Yes', value: 1},
      {text: 'No', value: 0},
    ],
  }),
  computed: {
    pet: {
      get () {
        return this.$store.state.pet.pet;
      },
      set (value) {
        this.$store.commit('pet/SET_PET', value);
      }
    },
    today () {
      return moment().format('Y-MM-DD')
    },

  },
  methods: {
    validateBreedSelection(value) {
      // Reset the search input if the selection is not valid (not in options)
      if (!this.breeds.find(option => option === value)) {
        this.pet.breed = null;
      }
    },
    placeChanged: function (address_components, placeResultData) {
      this.pet.vet = placeResultData.name
      this.pet.vet_address = placeResultData.formatted_address
      this.pet.vet_phone = placeResultData.international_phone_number
    },
    updateVetName: function (val) {
      this.pet.vet = val.newVal
    },
    updateVetAddress: function (val) {
      this.pet.vet_address = val.newVal
    },
    async onSubmit () {
      this.loading = true;
      let pet = Object.assign({}, this.pet);
      if(this.hasBirthday === false) {
        pet.dob = moment().subtract(this.years, 'years').subtract(this.months, 'months').format('Y-M-D')
      }
      pet.notes = this.notes
      let response = await PetsRepository.update(this.pet.uuid, pet)
      if(response.uuid !== undefined) {
        if(this.files.length > 0) {
          await PetsRepository.processUploadedProfilePhoto(this.pet.uuid, this.files[0])
        }
        this.loading = false;
        this.$router.push({ name: 'ViewPets' });
      }
      this.loading = false;
    },
  },
}
</script>
